/* Reset css */
.btn:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

html {
  font-size: 100%;
}

body {
  font-size: 0.875rem;
}

.fw-600 {
  font-weight: 600 !important;
}

#root,
html,
body {
  height: 100%;
}

.breadcrumb-item.active::before {
  --cui-breadcrumb-divider: "'>'" !important;
}

a {
  text-decoration: none !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, ">");
}

.bg-primary {
  background-color: #f26f21 !important;
}

.text-muted {
  color: #80949d !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: #04ac9c !important;
}

/* Sidebar */
.mysidebar {
  background: #212223;
  width: 280px !important;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  transition: width 0.25s;
  --cui-sidebar-brand-bg: transparent;
  --cui-sidebar-toggler-bg: transparent;
  --cui-sidebar-width: 280px;
  --cui-sidebar-nav-link-active-bg: #3a4348;
  --cui-sidebar-nav-link-hover-bg: #3a4348;
  --cui-sidebar-nav-group-bg: #212223;
}

.mysidebar.collapsed {
  width: 82px !important;
}

.nav-link.nav-group-toggle {
  padding: 0;
  border-radius: 6px;
  height: 38.75px;
}

.text-primary {
  --bs-text-opacity: 1;
  color: #f26f21 !important;
}

.btn-outline-primary {
  --bs-btn-color: #f26f21;
  --bs-btn-border-color: #f26f21;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f26f21;
  --bs-btn-hover-border-color: #f26f21;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #f26f21;
  --bs-btn-active-border-color: #f26f21;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f26f21;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f26f21;
  --bs-gradient: none;
  --cui-btn-color: #bd5316;
  --cui-btn-border-color: #bd5316;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #bd5316;
  --cui-btn-hover-border-color: #bd5316;
  --cui-btn-focus-shadow-rgb: 50, 31, 219;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #bd5316;
  --cui-btn-active-border-color: #bd5316;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #bd5316;
  --cui-btn-disabled-bg: transparent;
  --cui-gradient: none;
}

/* Btn primary */
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f26f21;
  --bs-btn-border-color: #f26f21;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bd5316;
  --bs-btn-hover-border-color: #bd5316;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #bd5316;
  --bs-btn-active-border-color: #f26f21;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f26f21;
  --bs-btn-disabled-border-color: #f26f21;

  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #f26f21;
  --cui-btn-border-color: #bd5316;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #bd5316;
  --cui-btn-hover-border-color: #bd5316;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #f26f21;
  --cui-btn-active-border-color: #f26f21;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #f26e218c;
  --cui-btn-disabled-border-color: #f26e218c;
}

/* Table */
.table-wrapper {
  border: 1px solid #dbe1e4;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

table > thead {
  background-color: #f3f5f6;
  color: #6b818c;
  font-size: 12px;
  padding: 16px 0 !important;
}

table > tbody {
  color: #6b818c;
  font-size: 14px;
}

/* Pagination */
.pagination > .page-item > .page-link {
  border: none;
  font-size: 14px;
}

.pagination > .page-item > .page-link {
  color: #6b818c;
}

.pagination > .page-item.disabled > .page-link {
  color: #aebbc1 !important;
}

.pagination > .page-item.active > .page-link {
  background: transparent;
  color: #f26f21;
}

.pagination > .page-item:first-child > .page-link,
.pagination > .page-item:last-child > .page-link {
  border: 1px solid #aebbc1;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #3a4348;
}

.pagination > .page-item:first-child {
  margin-right: auto;
  margin-left: 16px;
}

.pagination > .page-item:last-child {
  margin-left: auto;
  margin-right: 16px;
}

/* Tabbar */
.nav.nav-tabs {
  padding-bottom: 1px;
  border-bottom: 1px #dbe1e4 solid;
}

.nav.nav-tabs > .nav-item > .nav-link {
  border: none;
  color: #6b818c;
  font-weight: 600;
  padding: 10px 16px;
  border-radius: 0;
  cursor: pointer;
}

.nav.nav-tabs > .nav-item > .nav-link.active {
  color: #f26f21;
  background: #fef2ec;
  border-bottom: 2px solid #f26f21;
}

/* Filter bar */
.filter-bar-wraper {
  background: #f3f5f6;
  border-radius: 8px;
}

.filter-label {
  font-weight: 600;
  font-size: 14px;
}

.filter-form-select {
  font-weight: 600;
}

/* Modal */
.modal-header-description {
  font-size: 16px;
  font-weight: 500 !important;
}

/* Input file */
.input-drag-file {
  border-radius: 8px;
  width: 100%;
  max-width: 450px;
  border: 1px solid #aebbc1;
}

.breadcrumb-link {
  text-decoration: none;
}

.text-limited {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 150px;
}