.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

.button {
    border: none;
    background-color: #F26F21;
    color: #fff;
    border-radius: 10px;
    margin-top: 5px;
}